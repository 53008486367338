<template>
    <div id="login">
        <ValidationObserver v-slot="{invalid, handleSubmit}">
            <form class="u-col-12 u-col-sm-6 u-col-md-4 u-col-lg-4 u-col-xl-4" @submit.prevent="handleSubmit(onSubmit($event))">
                <div class="form-group">
                    <label for="username">
                        Email
                        <span class="required-asterisk">*</span>
                    </label>
                    <ValidationProvider rules="required|min-max:6,40|email" v-slot="{classes, errors}">
                        <input id="username" type="text" v-model="username" name="username" class="form-control" v-bind:class="classes"/>
                        <div class="err-wrapper">
                            <div v-for="err in errors">{{ err }}</div>
                        </div>
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <label for="password">
                        Password
                        <span class="required-asterisk">*</span>
                    </label>
                    <ValidationProvider rules="required|min-max:6,15" v-slot="{classes, errors}">
                        <input id="password" type="password" v-model="password" name="password" class="form-control" v-bind:class="classes" autocomplete="true"/>
                        <div class="err-wrapper">
                            <div v-for="err in errors">{{ err }}</div>
                        </div>
                    </ValidationProvider>
                    <div class="error-password" v-show="inValidPassword">Пароль введен не верно. <span @click="resetPasswordSendEmail()">Забыли пароль?</span></div>
                </div>
                <div class="form-controls">
	                <c_button :c_class="'def-btn'" :c_loading="loading" c_text="ВХОД"></c_button>
                </div>
            </form>
        </ValidationObserver>
        <div class="socials-auth-wrapper">
            <social-buttons :isLogin="true"></social-buttons>
            <div class="privacy-confirmation">
                <img src="../../../assets/img/privacy-no-one.svg" alt="privacy">
                <p>Мы никогда не опубликуем ничего без вашего ведома, и вы всегда сможете скрыть любые данные</p>
            </div>
        </div>
        <!--<button type="button" @click="logout">logout</button>-->
    </div>
</template>

<script>
    import {authService} from "../../../_api";
    import SocialButtons from "../../../components/auth/SocialsLogin";
    import C_button from "@/components/_shared/Button";
    export default {
        components: {
	        C_button,
            'social-buttons': SocialButtons,
        },
        data () {
            return {
                username: '',
                password: '',
                submitted: false,
                loading: false,
                inValidPassword: false
            }
        },
        computed: {},
        created () {
            localStorage.removeItem('uReg');
            localStorage.removeItem('socialRegUser');
        },
        methods: {
            resetPasswordSendEmail() {
                authService.resetPasswordSendEmail({email: this.username}).then(res => {
                    this.$router.push({path: '/registration/email-confirmation', query: {resetPassword: true, email: this.username}});
                }).catch(err => {
                    this.$store.dispatch('alert/error', err.response.data, { root: true });
                })
            },
            validateEmail(email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            onSubmit (e) {
                this.submitted = true;
                if (this.validateEmail(this.username) && this.password.length >= 6) {
                    this.loading = true;
                    authService.login(this.username, this.password).then(res => {
                        this.loading = false;
                    }).catch(err => {
                        this.inValidPassword = false;
                        this.loading = false;

                        if (err.response.status === 401) {
                            this.inValidPassword = true;
                            this.$store.dispatch('alert/error', "Неправильный пароль", { root: true });
                        } else if (err.response.status === 404) {
                            this.$store.dispatch('alert/error', 'Такого пользователя не существует', { root: true });
                        } else {
                            this.$store.dispatch('alert/error', err.response.data, { root: true });
                        }

                    })
                }
            },
            logout() {
                this.$store.dispatch('authentication/logout')
            }
        },
        watch: {}
    }
</script>

<style lang="less" scoped>

    @import "../../../assets/styles/_vars.less";

    #login {
        width: 100%;
        form {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 auto;
            .form-group {
                .error-password {
                    font-size: 13px;
                    margin-top: 15px;
                    span {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
                width: 100%;
                label {
                    text-align: left;
                    margin: 0;
                    width: 100%;
                    font-size: 14px;
                    .required-asterisk {
                        color: #840000;
                    }
                }
                input, textarea {
                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
                .err-wrapper {
                    text-align: left;
                    font-size: 12px;
                    div {
                        color: #840000;
                    }
                }
            }
            .form-controls {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
	            .def-btn {
		            min-height: 36px;
		            min-width: 260px;
		            font-size: 14px;
	            }
            }
        }
        .socials-auth-wrapper {
            width: 100%;
            min-width: 260px;
            max-width: 260px;
            margin: 50px auto;
            .socials-title {
                text-align: left;
                color: #666666;
                font-size: 18px;
                margin-bottom: 29px;
                line-height: 21px;
            }
            .socials-btns-wrapper {
                margin-bottom: 25px;
                button {
                    background-repeat: no-repeat;
                    width: 100%;
                    background-color: @theme_color;
                    color: #ffffff;
                    border-radius: 51px;
                    font-size: 13px;
                    font-weight: 500;
                    text-transform: uppercase;
                    border: none;
                    padding: 9.5px 22px;
                    background-position: 8% 50%;
                    &:focus {
                        outline: none;
                    }
                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }
                    &.fb-btn {
                        background-image: url("../../../assets/img/Icon-social-facebook.svg");
                    }
                    &.vk-btn {
                        background-image: url("../../../assets/img/Icon-social-vk.svg");
                    }
                }
                p {
                    color: #A7A7A7;
                    font-size: 18px;
                    font-weight: 500;
                    margin-bottom: 12px;
                }
            }
            .privacy-confirmation {
                background: #F8F8F8;
                border-radius: 5px;
                padding: 31px 24px 21px 24px;
                img {
                    width: 40px;
                    margin-bottom: 16px;
                }
                p {
                    color: #71809C;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                }
            }
        }
    }


    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {

    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #login {
            .socials-auth-wrapper {
                .privacy-confirmation {

                }
            }
        }
    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {

    }
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
</style>
